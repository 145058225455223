import { ReactNode } from "react";
import useRootStore from "../../hooks/rootStoreHook";
import { Navigate, Outlet } from "react-router";
import { observer } from "mobx-react-lite";
import { LoadingWrapper } from "../LoadingWrapper";

const AdminGuard = ({ children }: { children?: ReactNode }) => {
  const { sessionStore } = useRootStore();
  const currentSpace = sessionStore?.currentSpace;

  if (currentSpace === null) {
    return (
      <LoadingWrapper loaders={[{ isLoading: true, text: "Loading page..." }]}>
        Loading...
      </LoadingWrapper>
    );
  }

  const isCleverComplianceSpace =
    currentSpace?.name === "Clever Compliance" ||
    currentSpace?.name === "localhost license";

  if (!isCleverComplianceSpace) {
    return <Navigate to="/" />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default observer(AdminGuard);
